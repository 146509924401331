
import React, { lazy, Suspense, useRef } from 'react'
import { Switch, Route } from 'react-router'

const ADASBoardWeeklyEquipmentChecks = lazy(() => import('../containers/ADASBoardWeeklyEquipmentChecks'));
const ADASCoverage = lazy(() => import('../containers/ADASCoverage'));
const ADASCoverageAdmin = lazy(() => import('../containers/ADASCoverageAdmin'));
const ADASSafetyChecks = lazy(() => import('../containers/ADASSafetyChecks'));
const AutoglassLocations = lazy(() => import('../containers/AutoglassLocations'));
const AutoglassLocationsAdmin = lazy(() => import('../containers/AutoglassLocationsAdmin'));
const BillingManualApp = lazy(() => import( '../containers/BillingManualApp'));
const BillingManualAdmin = lazy(() => import( '../containers/BillingManualAdmin'));
const BranchUpdateForm = lazy(() => import( '../containers/BranchUpdateForm'));
const CECCustomerEmailRequests = lazy(() => import('../containers/CECCustomerEmailRequests'));
const CECDashboard = lazy(() => import('../containers/CECDashboard'));
const CECEmailReferral = lazy(() => import('../containers/CECEmailReferral'));
const CompetitorIntelForm = lazy(() => import( '../containers/CompetitorIntelForm'));
const CorrectiveActionReport = lazy(() => import( '../containers/CorrectiveActionReport'));
const DistrictSupportTeamToolKit = lazy(() => import('../containers/DistrictSupportTeamToolKit'));
const LaddawSOPDocumentsAdmin = lazy(() => import( '../containers/LaddawSOPDocumentsAdmin'));
const LaddawSOPSignOff = lazy(() => import( '../containers/LaddawSOPSignOff'));
const MonthlySiteAssessment = lazy(() => import( '../containers/MonthlySiteAssessment'));
const OraclePermissionsReviewApp = lazy(() => import( '../containers/OraclePermissionsReviewApp'));
const SubcontractorCoverage = lazy(() => import('../containers/SubcontractorCoverage'));
const Tech121s = lazy(() => import( '../containers/Tech121s'));
const TechnicalDataSheetDocumentsAdmin= lazy(() => import( '../containers/TechnicalDataSheetDocumentsAdmin'));
const TechnicalDataSheetsSopSignOff = lazy(() => import( '../containers/TechnicalDataSheetsSopSignOff'));
const TechnicianSOPDocumentsAdmin = lazy(() => import( '../containers/TechnicianSOPDocumentsAdmin'));
const TechnicianSOPSignOff = lazy(() => import( '../containers/TechnicianSOPSignOff'));
const TechnicianToolsSOPSignOff = lazy(() => import( '../containers/TechnicianToolsSOPSignOff'));
const TTPATTesting = lazy(() => import( '../containers/TTPATTesting'));
const VehicleWeeklyCheckList = lazy(() => import( '../containers/VehicleWeeklyCheckList'));
const Welcome = lazy(() => import( '../containers/Welcome'));
const WiperFinderAdmin = lazy(() => import( '../containers/WiperFinderAdmin'));
const WiperFinderApp = lazy(() => import( '../containers/WiperFinderApp'));
const InsuranceSearch = lazy(() => import( '../containers/InsuranceSearch'));
const BoschGuns = lazy(() => import( '../containers/BoschGuns'));

const routes = [
    // {
    //     path: "/apps/technicians/sop/tools_and_equipment",
    //     component: TechnicianToolsSOPSignOff
    // },
    {
        path: "/apps/locations/pat_testing",
        component: TTPATTesting,
    },
    {
        path: "/apps/vehicle/adas/coverage",
        component: ADASCoverage,
    },
    {
        path: "/apps/admin/vehicle/adas/coverage",
        component: ADASCoverageAdmin,
    },
    {
        path: "/apps/locations/subcontractor_coverage",
        component: SubcontractorCoverage,
    },
    {
        path: "/apps/locations/autoglass",
        component: AutoglassLocations,
    },
    {
        path: "/apps/cec/email/referral",
        component: CECEmailReferral,
    },
    {
        path: "/apps/cec/email/customer_request",
        component: CECCustomerEmailRequests,
    },
    {
        path: "/apps/cec/dashboard",
        component: CECDashboard,
    },
    {
        path: "/apps/dst/dashboard",
        component: DistrictSupportTeamToolKit,
    },
    {
        path: "/apps/vehicle/warranty/corrective_action_report",
        component: CorrectiveActionReport,
    },
    {
        path: "/apps/locations/monthly_site_checks",
        component: MonthlySiteAssessment,
    },
    {
        path: "/apps/technician/one_to_ones",
        component: Tech121s,
    },
    {
        path: "/apps/technician/technical_data_sheets/signoff",
        component: TechnicalDataSheetsSopSignOff,
    },
    {
        path: "/apps/laddaw/signoff",
        component: LaddawSOPSignOff,
    },
    {
        path: "/apps/technician/signoff",
        component: TechnicianSOPSignOff,
    },
    {
        path: "/apps/locations/adas/weekly_equipment_checks",
        component: ADASBoardWeeklyEquipmentChecks,
    },
    {
        path: "/apps/vehicle/adas",
        component: ADASSafetyChecks,
    },
    {
        path: "/apps/permissions/review_table",
        component: OraclePermissionsReviewApp,
    },
    {
        path: "/apps/admin/permissions/review_table",
        component: OraclePermissionsReviewApp,
    },
    {
        path: "/apps/vehicle/wiper_finder",
        component: WiperFinderApp,
    },
    {
        path: "/apps/admin/vehicle/wiper_finder",
        component: WiperFinderAdmin,
    },
    {
        path: "/apps/billing/direct_billing_manual",
        component: BillingManualApp,
    },
    {
        path: "/apps/branches",
        component: BranchUpdateForm,
    },
    {
        path: "/apps/competitors/intelligence",
        component: CompetitorIntelForm,
    },
    {
        path: "/apps/admin/competitors/intelligence",
        component: CompetitorIntelForm,
    },
    {
        path: "/apps/vehicle/weekly_safety_checklist",
        component: VehicleWeeklyCheckList,
    },
    {
        path: "/apps/admin/locations/autoglass",
        component: AutoglassLocationsAdmin,
    },
    {
        path: "/apps/admin/billing/direct_billing_manual",
        component: BillingManualAdmin,
    },
    {
        path: "/apps/admin/technician/technical_data_sheets/signoff",
        component: TechnicalDataSheetDocumentsAdmin,
    },
    {
        path: "/apps/admin/laddaw/signoff",
        component: LaddawSOPDocumentsAdmin,
    },
    {
        path: "/apps/admin/technician/signoff",
        component: TechnicianSOPDocumentsAdmin,
    },
    {
      path: "/apps/technicians/sop/tools_and_equipment",
      component: TechnicianToolsSOPSignOff,
    },
	{
        path: "/apps/insurance/insurance_search",
        component: InsuranceSearch,
    },
	{
        path: "/apps/bosch/bosch_guns",
        component: BoschGuns,
    },
];


// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = (props) => {
    
    let parentProps = {...props};
    parentProps.main_container = useRef();

    const NoPageFound = () => <h3>404 - Not found</h3>;
    const NoMatchPage = (localprops) => {
      
      if (!localprops.isAuthenticated) {
        return <React.Fragment>
          <h3>401 - Not logged in</h3>
          <h4>
            It looks like you are not logged in. Please log in using your user settings menu at the top right of the page and clicking Sign In.
          </h4>
        </React.Fragment>
      } else if (localprops.isLoading) {
        return <div>Loading...</div>
      }
      
      return <React.Fragment>
        <h3>401 - Unauthorized</h3>
        <h4>
          You do not have access to this page. If you think you should have access then please contact IT
        </h4>
      </React.Fragment>
    }

    const hasAccess = (pathname) => {
        const page = props.menuAccess.find((menu) => menu.items ? menu.items.find(item => item.href.startsWith(pathname)) : false);

        if (page) return true;
        else return false;
    }

    // the switch statement is going to look really messy as we need to pass isAuthenticated and 
    return (
      <main role="main" className="flex-shrink-0">
        <div id="main-container" className="container" ref={parentProps.main_container}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/"
                render={(props) => (
                  <Welcome 
                    {...props}
                    {...parentProps} 
                  />
                )} 
              />
              {routes.map((route, i) => (
                <Route 
                  key={i} 
                  exact 
                  path={route.path}
                  render={(props) => {
                    if (parentProps.isAuthenticated && hasAccess(props.location.pathname)) { 
                      return (
                        <route.component
                          {...props}
                          {...parentProps} 
                        />
                      )
                    } else {
                      return <NoMatchPage
                        {...props}
                        {...parentProps} 
                      />
                    }
                  }} 
                />
              ))}
              <Route component={NoPageFound} />
            </Switch>
          </Suspense>
        </div>
      </main>
    );
}

export default Main
