import React from 'react'
import { Link } from 'react-router-dom';

import Button from 'devextreme-react/ui/button';
import Menu from 'devextreme-react/ui/menu';

import '../App.css';

// The Header creates links that can be used to navigate
// between routes.
const Header = (props) => {
   
    const onUserMenuClick = (e) => {
        if (e.itemData.name && e.itemData.name.startsWith("Sign")) {
            props.authButtonMethod();
        }
    }

    return (
        <React.Fragment>
            <header className="container-fluid ag-header">
                <div className="" style={{ display: "grid",  gridTemplateColumns: '1fr 1fr 1fr'}} >
                    <Button 
                        height={50} 
                        width={40}
                        icon={"menu"} 
                        onClick={props.changeDrawerState}
                    />
                    <Link style={{textAlign: "center"}} to="/" className="navbar-brand ag-logo-link" >
                        <img src="https://www.autoglass.co.uk/fileadmin/template/assets/bu/uk/logo.gif" className="ag-logo" alt="Navbar" />
                    </Link>
                    <div style={{textAlign: "right"}}>
                        <Menu 
                            dataSource={[{ 
                                icon: "user", 
                                items: [{ 
                                    name: props.isAuthenticated ? "Sign Out" : "Sign In" 
                                }],
                            }]}
                            displayExpr="name"
                            onItemClick={onUserMenuClick}
                            showSubmenuMode={"onClick"}
                            showFirstSubmenuMode={"onClick"}
                            height={50} 
                            width={40}
                            className={"dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"} 
                        />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header
