const graph = require('@microsoft/microsoft-graph-client');

const getAuthenticatedClient = (accessToken) => {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });

    return client;
}

export const getUserDetails = async(accessToken) => {
    const client = getAuthenticatedClient(accessToken);

    const user = await client.api('/me?$select=businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,department').get();
    return user;
}
