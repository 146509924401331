import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';

import Drawer from 'devextreme-react/ui/drawer';

import NavigationList from './NavigationList.js';

import '../App.css';

const Menu = (props) => {

    const parentProps = props;
    
    let drawerOpened = parentProps.drawerOpened;

    const drawerRef = React.useRef(null);
    
    const [drawerWidth, setWidth] = useState(295);
    const [drawerHeight, setHeight] = useState(400);
    
    useEffect(() => {
        // Update the document title using the browser API        
        if (drawerWidth < document.getElementById("main-container").offsetLeft) {
            setWidth(document.getElementById("main-container").offsetLeft);
            drawerRef.current.instance.repaint();
        }

        const widgetHeight = Math.max(document.getElementsByTagName("main")[0].clientHeight, (window.innerHeight - (document.getElementsByTagName("footer")[0].clientHeight * 2)));

        if (drawerHeight !== widgetHeight) {
            setHeight(widgetHeight);
            drawerRef.current.instance.repaint();
        }

        // Close drawer if location.state.hideDrawer has been set. Then reset location.state to undefined
        if (drawerOpened && parentProps.location.state && parentProps.location.state.hideDrawer) {
            parentProps.changeDrawerState();
            parentProps.history.replace(parentProps.location.pathname + parentProps.history.location.search);
        }
    }, [drawerWidth, drawerHeight, drawerOpened, parentProps]);

    const renderDrawerComponent = (props) => {
        return <NavigationList 
            menuAccess={parentProps.menuAccess} 
            drawerWidth={drawerWidth} 
            drawerHeight={drawerHeight} 
            setParentHeight={setHeight}
            drawerRef={drawerRef} 
            changeDrawerState={parentProps.changeDrawerState}
        />
    }

    return (
        <React.Fragment>
            <Drawer
                ref={drawerRef}
                id={"main-menu-drawer"}
                opened={drawerOpened}
                openedStateMode={'overlap'}
                position={'left'}
                revealMode={'slide'}
                render={renderDrawerComponent}
                closeOnOutsideClick={true}
                height={drawerHeight}
                width={drawerWidth}
            /> 
        </React.Fragment>
    )
}

export default withRouter(Menu);
