import React, { Component } from 'react';
import { UserAgentApplication } from 'msal';
import notify from 'devextreme/ui/notify';

import './App.css';
import config from './Config';
import { getUserDetails } from './components/GraphService';
import Main from './components/Main';
import Menu from './components/Menu';
import Header from './components/Header';
import Footer from './components/Footer';
import customFetch from './components/FetchHandler';

class App extends Component {
    constructor(props) {
        super(props);
  
        this.userAgentApplication = new UserAgentApplication({
            auth: {
                clientId: config.appId,
                redirectUri: window.location.origin, //defaults to application start page
                postLogoutRedirectUri: window.location.origin
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        });
  
        const user = this.userAgentApplication.getAccount();
  
        this.state = {
            isAuthenticated: (user !== null),
            user: {},
            menuAccess: [],
            error: null,
            base_url: window.location.origin,
            // during dev mode add proxy: http://localhost:5000 to package.json
            drawerOpened: false,
            isLoading: true
        };
  
        if (user) {
            // Enhance user object with data from Graph
            this.getUserProfile();
        }

        this.changeDrawerState = this.changeDrawerState.bind(this);
    }

    componentDidMount() {
        //this.getMenuAccess();
    }

    async login() {
        
        try {
            await this.userAgentApplication.loginPopup({
                scopes: config.scopes,
                prompt: "select_account"
            });
            await this.getUserProfile();
            //await this.getMenuAccess();
            
        }
        catch(err) {
            let error = {};
      
            if (typeof(err) === 'string') {
                
                const errParts = err.split('|');
                error = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: err };
            } else {
                error = {
                    message: err.message,
                    debug: JSON.stringify(err)
                };
            }

            this.setState({
                isAuthenticated: false,
                user: {},
                error: error
            });
        }
    }

    logout() {
        this.setState({
            isAuthenticated: false,
            user: {},
            menuAccess: []
        });
        this.userAgentApplication.logout();
    }

    async getUserProfile() {
        
        try {
            // Get the access token silently
            // If the cache contains a non-expired token, this function
            // will just return the cached token. Otherwise, it will
            // make a request to the Azure OAuth endpoint to get a token
            const accessToken = await this.userAgentApplication.acquireTokenSilent({
                scopes: config.scopes
            });
    
            if (accessToken) {
                // Get the user's profile from Graph
                const user = await getUserDetails(accessToken);

                if (!sessionStorage.getItem("wa_user_id")) {
                    window.sessionStorage.setItem("wa_user_id", user.id);
                }

                const menuList = await this.getMenuAccess(user.id);

                this.setState({
                    isAuthenticated: true,
                    user: {
                      displayName: user.displayName,
                      email: user.mail || user.userPrincipalName,
                      location: user.officeLocation,
                      department: user.department,
                      region: user.officeLocation,
                      id: user.id,
                    },
                    error: null,
                    menuAccess: menuList,
                    isLoading: false
                });
            }
        } catch(err) {
            let error = {};

            if (typeof(err) === 'string') {
                const errParts = err.split('|');
                error = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: err };
            } else {
                error = {
                    message: err.message,
                    debug: JSON.stringify(err)
                };
            }

            this.setState({
                isAuthenticated: false,
                user: {},
                error: error
            });
        }
    }

    async getMenuAccess(userid) {

        try {

            const request = new Request(`${this.state.base_url}/api/menu/access?userid=${userid}`);  

            const menuData = await customFetch(request);

            return menuData.menuList;

        } catch(e) {
            console.log("Something went wrong", e);
            return []
        }

    }

    changeDrawerState() {
        this.setState({ drawerOpened: !this.state.drawerOpened });
    }

    render() {
        
        if (this.state.error) {
            notify({
                message: this.state.error.message,
                position: {
                  my: 'center top',
                  at: 'center top'
                }
            }, "error", 3000);
        }

        return (
          <React.Fragment>
            <Header 
              isAuthenticated={this.state.isAuthenticated}
              user={this.state.user}
              menuAccess={this.state.menuAccess}
              authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
              changeDrawerState={this.changeDrawerState}
            />
            <Menu
                isAuthenticated={this.state.isAuthenticated}
                user={this.state.user}
                menuAccess={this.state.menuAccess}
                authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
                drawerOpened={this.state.drawerOpened}
                changeDrawerState={this.changeDrawerState}
            />
            <Main 
              isAuthenticated={this.state.isAuthenticated}
              user={this.state.user}
              menuAccess={this.state.menuAccess}
              authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
              error={this.state.error}
              base_url={this.state.base_url}
              isLoading={this.state.isLoading}
            />
            <Footer />
          </React.Fragment>
        );
    }
}

export default App;
