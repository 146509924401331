
const updateOptions = options => {
    const update = { ...options };

    update.headers = {
        ...update.headers,
        // 'Content-Type': "application/json",
        'WA-USER-ID': window.sessionStorage.getItem("wa_user_id"),
    };

    return update;
}

const customFetch = (url, options) => new Promise((resolve, reject) => { 
    console.log("url", url);
    console.log("options", updateOptions(options));
    fetch(url, updateOptions(options))
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(e => reject(e))
});

export default customFetch;
