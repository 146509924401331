import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import List from 'devextreme-react/ui/list';

const NavigationList = (props) => {

    const parentProps = props;
    const menuItems = parentProps.menuAccess.length > 0 ? parentProps.menuAccess : [];

    const itemClicked = (e) => {
        if (e.itemData.href) {
            parentProps.history.push(e.itemData.href, { hideDrawer: true });
        }
    }

    useEffect(() => {  

      // for some reason parent isnt re-rendering so update the height and repaint it here
      const widgetHeight = Math.max(document.getElementsByTagName("main")[0].clientHeight, (window.innerHeight - (document.getElementsByTagName("footer")[0].clientHeight * 2)));

      if (parentProps.drawerHeight !== widgetHeight) {
          parentProps.setParentHeight(widgetHeight);
          parentProps.drawerRef.current.instance.repaint();
      }

    });

    return <List
          id={"main-menu-list"}
          items={menuItems}
          hoverStateEnabled={true}
          width={parentProps.drawerWidth}
          height={parentProps.drawerHeight}
          displayExpr={"name"}
          onItemClick={itemClicked}
          grouped={true}
          groupKeyFn={"key"}
    />

}

export default withRouter(NavigationList);
