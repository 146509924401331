import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const Copyright = () => {
    return (
      <Typography id="testing" variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.autoglass.co.uk/">
          BelronUK T/A Autoglass
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const StickyFooter = () => 
  <footer className="footer mt-auto py-3">
    <div className="container">
    <Container maxWidth="sm">
      {/*<Typography variant="body1">Queen Daenerys Stormborn of the House Targaryen, the First of Her Name, Queen of the Andals, the Rhoynar and the First Men, Lady of the Seven Kingdoms and Protector of the Realm, Lady of Dragonstone, Queen of Meereen, Khaleesi of the Great Grass Sea, the Unburnt, Breaker of Chains and Mother of Dragons.</Typography>*/}
      <Copyright />
    </Container>
    </div>
  </footer>

export default StickyFooter;
